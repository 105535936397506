<template>
    <div class="container">
        <div v-html="content"></div>
    </div>
</template>

<script setup>
//
import { onMounted, onUnmounted, ref, watch } from "vue";
import { http } from "@/http";
import { Toast } from "vant";
import { useRoute, useRouter } from "vue-router";

const content = ref(null);

onMounted(()=>{
    let params = { code: 'ELE_RETAIL'};
    http.post('/?cmd=com.xqxc.api.pigear.biz.account.ShopInfoService.getAuthInfo&group=earApp', params).then(res => {
        content.value = res.result.authInfo || '';
    }).catch(err => {
        Toast(err.errorName);
    });
});

</script>

<style lang="scss" scoped>
.container{
    padding: .3rem;
    word-break: break-all;
    line-height: 1.5;
    img{
        max-width: 100%;
    }
    p{
        padding: .1rem 0;
    }
}
</style>